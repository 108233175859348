<template>
    <div v-if="shouldDisplay" class="app-banner" @click.capture="trackClick">
        <a :href="appLink" class="main-link">
            <div class="icon-container">
                <img src="/img/icons/apple-touch-icon.png" alt="ISeeChange app icon" class="icon">
            </div>

            <div class="content">
                ISeeChange is on {{ storeName }}
                <div class="faux-link">Get the App!</div>
            </div>
        </a>

        <v-btn outlined small icon class="close-button" aria-label="Dismiss app banner" @click="dismiss">
            <v-icon>close</v-icon>
        </v-btn>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import moment from 'moment';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/config';
import { trackAppLinkClick } from '@/tracking';

export default Vue.extend({
    name: 'AppBanner',

    data() {
        const dismissedDate = localStorage.getItem('app-banner-dismissed');
        const deviceType = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)?.[0];

        return {
            dismissed: Boolean(dismissedDate) && moment().diff(dismissedDate, 'weeks') < 6,
            devicePlatform: deviceType && (deviceType.toLowerCase() === 'android' ? 'ANDROID' : 'IOS'),
        };
    },

    computed: {
        appPlatform() {
            return this.$store.state.platform;
        },

        shouldDisplay(): boolean {
            return Boolean(!this.dismissed && this.appPlatform === 'web' && this.devicePlatform);
        },

        storeName(): string {
            return this.devicePlatform === 'ANDROID' ? 'Google Play' : 'the App Store';
        },

        appLink(): string {
            return this.devicePlatform === 'ANDROID' ? ANDROID_APP_LINK : IOS_APP_LINK;
        },
    },

    methods: {
        trackClick() {
            if (this.devicePlatform) {
                trackAppLinkClick(this.devicePlatform.toLowerCase());
            }
        },
        dismiss() {
            localStorage.setItem('app-banner-dismissed', new Date().toISOString());
            this.dismissed = true;
        },
    }
});
</script>

<style lang="postcss" scoped>
.app-banner {
    align-items: center;
    background: white;
    color: black;
    display: flex;
    font-size: 1.1rem;
    padding: 0 0.5rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
}

.main-link {
    display: contents;
}

.app-banner > *,
.main-link > * {
    margin: 1rem 0.5rem;
}

.app-banner a {
    color: inherit;
    text-decoration: inherit;
}

.icon-container {
    flex-shrink: 0;
}

.icon {
    background: linear-gradient(#fff3, #0003), var(--color-secondary);
    border-radius: 5px;
    display: block;
    height: 3em;
    padding: 0.3rem;
    width: 3em;
}

.content {
    flex-grow: 1;
}

.faux-link {
    color: var(--color-primary);
}
</style>
