<template>
    <v-dialog v-model="showing" fullscreen scrollable :transition="false" content-class="onboarding-root-dialog">
        <cms-content v-slot="{ document }" type="onboarding">
            <template v-if="document">
                <img :src="document.data.steps[0].background_image.url" alt="" class="preloaded-image">

                <section v-if="inIntro" class="intro step from-cms">
                    <!-- This spacer centers the intro step's content. -->
                    <div />

                    <div class="step-content">
                        <prismic-rich-text :field="document.data.steps[0].headline" class="slide-title" />
                        <prismic-rich-text :field="document.data.steps[0].body" class="slide-description" />
                    </div>

                    <div class="step-footer">
                        <base-button large rounded color="primary" prepend-icon="arrow_forward" @click="inIntro = false">
                            {{ $t('onboarding.start') }}
                        </base-button>
                        <br>
                        <base-button large text color="primary" @click="showing = false">{{ $t('actions.skip') }}</base-button>
                    </div>
                </section>

                <slippy-carousel
                    v-if="!inIntro"
                    ref="carousel"
                    dot-position="top"
                    class="carousel"
                >
                    <section v-for="(step, i) in document.data.steps.slice(1)" :key="i" class="step from-cms" :style="`--step-background: url(${step.background_image.url});`">
                        <div class="step-content">
                            <prismic-rich-text :field="step.headline" class="slide-title" />
                            <prismic-rich-text :field="step.body" class="slide-description" />
                        </div>

                        <div class="step-footer">
                            <!-- On the last step, continue to registration. -->
                            <auth-button
                                v-if="i === document.data.steps.length - 2"
                                fab
                                color="primary"
                                :aria-label="$t('actions.next')"
                                @click="showing = false"
                            >
                                <v-icon>arrow_forward</v-icon>
                            </auth-button>

                            <base-button
                                v-else
                                fab
                                color="primary"
                                :aria-label="$t('actions.next')"
                                @click="$refs.carousel.scrollTo($refs.carousel.internalValue + 1)"
                            >
                                <v-icon>arrow_forward</v-icon>
                            </base-button>

                            <div class="mt-2">
                                <v-icon style="color: inherit;">location_on</v-icon>
                                <span class="d-sr-only">{{ $t('onboarding.location') }}:</span>
                                {{ step.footer[0].text }}
                            </div>
                        </div>
                    </section>
                </slippy-carousel>

                <language-callout actual-class="language-callout" />
            </template>
        </cms-content>
    </v-dialog>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import CmsContent from '@/components/CmsContent.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import SlippyCarousel from '@/components/SlippyCarousel.vue';
import AuthButton from './AuthButton.vue';

const ONBOARDING_LOCALSTORAGE_KEY = 'onboarding-seen';

export default Vue.extend({
    name: 'Onboarding',

    components: {
        CmsContent,
        LanguageCallout,
        SlippyCarousel,
        AuthButton,
    },

    data() {
        return {
            mounted: false,
            internalSeenDate: null as Date | null,
            inIntro: true,
        };
    },

    computed: {
        showing: {
            get(): boolean {
                return this.mounted && this.internalSeenDate === null;
            },

            set(showing: boolean) {
                if (showing) {
                    try {
                        localStorage.removeItem(ONBOARDING_LOCALSTORAGE_KEY);
                    } finally {
                        this.internalSeenDate = null;
                    }
                } else {
                    const now = new Date();

                    try {
                        localStorage.setItem(ONBOARDING_LOCALSTORAGE_KEY, now.toISOString());
                    } finally {
                        this.internalSeenDate = now;
                    }
                }
            }
        }
    },

    mounted() {
        const seenTimestamp = localStorage.getItem(ONBOARDING_LOCALSTORAGE_KEY);

        if (seenTimestamp) {
            this.internalSeenDate = new Date(seenTimestamp);
        }

        this.mounted = true;
    },
});
</script>

<style lang="postcss">
.onboarding-root-dialog {
    overflow-x: hidden;
}
</style>

<style lang="postcss" scoped>
:deep(img) {
    height: auto;
}

.preloaded-image {
    height: 1px;
    opacity: 0.01;
    position: absolute;
    width: 1px;
}

:deep(.language-callout) {
    position: absolute;
    left: 50%;
    top: calc(env(safe-area-inset-top) + var(--spacing-4));
    transform: translateX(-50%);
    white-space: nowrap;
}

.carousel {
    --active-dot-color: var(--color-danger);
    background: var(--color-secondary);
    color: white;
    min-height: 100%;
}

.step {
    flex-direction: column;
    padding: 6rem 5vw 2rem;
}

.step.intro {
    --step-background: linear-gradient(90deg, var(--color-secondary-tint), var(--color-secondary));
    color: white;
    min-height: 100%;
    padding-top: 1rem;
    text-align: center;
}

.step.from-cms {
    background: linear-gradient(#000c 10%, #0000 70%, #0000 80%, #0009), var(--step-background);
    background-position: center center;
    background-size: cover;
    display: flex;
    font-size: var(--type-base);
    justify-content: space-between;
}

.step.from-cms :deep(h2) {
    color: var(--color-tertiary);
    font-family: var(--type-sans);
    font-size: var(--type-lead);
    margin-bottom: 1em;
    text-align: center;
}

.step-footer {
    text-align: center;
    margin: 0 -1.8rem;
}

.persistent-next-wrapper {
    bottom: 3em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
